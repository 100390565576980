import { Col, Row, Space, Typography } from 'antd'
import Link from 'next/link'
import React,{ useEffect, useState } from 'react'
import {
	FacebookIcon,
	LocationIcon,
	MessageIcon,
	PhoneIcon,
	YoutubeIcon,
	ZaloIcon,
} from 'src/components/CustomIcon'
import BASE_CONFIG from 'src/configs/base'
import { V4_ENDPOINT } from 'src/configs/API'
import LiveChat from '../LiveChat/livechat'
import menuServices from 'src/services/menu.service'
const Footer = () => {
	const [infoMenu, setInfoMenu] = useState({
		title: 'Về 247Express',
		size: 10,
		children: [],
	})

	const [helpMenu, setHelpMenu] = useState({
		title: 'Hỗ trợ khách hàng',
		size: 10,
		children: [],
	})

	useEffect(() => {
		Promise.all([menuServices.getFooterInfoMenuItems(), menuServices.getFooterHelpMenuItems()])
			.then((responses) => {
				const infoMenu = responses[0]
				const helpMenu = responses[1]

				setInfoMenu((prev) => ({
					...prev,
					children: infoMenu?.Children,
				}))

				setHelpMenu((prev) => ({
					...prev,
					children: helpMenu?.Children,
				}))
			})
			.catch(console.log)
			
	}, [])
	const LiveChatComponent = React.useMemo(() => <LiveChat />, []);

	return (
		<div className="wrapper-footer">
			<div className="container">
				<Row gutter={[16, 24]} style={{ marginRight: 0, marginLeft: 0 }}>
					<Col span={24}>
						<Link href="/"> 
							<a aria-label={BASE_CONFIG.PAGE_NAME}>
								{/* eslint-disable-next-line @next/next/no-img-element */}
								<img style={{height: 84}} className="logo-footer" src="/images/logo-f.png" alt={BASE_CONFIG.PAGE_NAME} />
							</a>
						</Link>
						<Typography.Title level={4} style={{ color: '#9F3123', padding: '12px 0' }}>
							Công Ty Cổ Phần Hai Bốn Bảy
						</Typography.Title>
					</Col>
					<Row gutter={[16, 24]}>
						<Col xs={24} md={24} lg={24} xl={8}>
							<Row gutter={[8, 12]}>
								<Col xs={24} md={24} lg={24} xl={24}>
									<Typography.Text className="text label">Địa chỉ:</Typography.Text>
								</Col>
								{/* <Col xs={24} md={24} lg={24} xl={24}>
									<Space>
										<LocationIcon />

										<Typography.Text className="text address">
											A77 Bạch Đằng, Phường 2, Quận Tân Bình, TP. Hồ Chí Minh
										</Typography.Text>
									</Space>
								</Col> */}
								<Col xs={24} md={24} lg={24} xl={24}>
									<Space>
										<LocationIcon />

										<Typography.Text className="text address">
										Văn phòng HCM: Tầng 8 Tòa nhà Samco, Số 326 Võ Văn Kiệt, Phường Cô Giang, Quận 1, TP.Hồ Chí Minh
										</Typography.Text>
									</Space>
								</Col>
								<Col xs={24} md={24} lg={24} xl={24}>
									<Space>
										<LocationIcon />

										<Typography.Text className="text address">
										Văn phòng HN: Tầng 12 Tòa nhà Vinacomin, Số 3 Dương Đình Nghệ, Phường Yên Hòa , Quận Cầu Giấy, TP.Hà Nội
										</Typography.Text>
									</Space>
								</Col>
								<Col style={{ marginTop: 16 }} xs={14} md={14} lg={12} xl={12}>
									<Typography.Text className="text label">Email:</Typography.Text>
								</Col>
								<Col style={{ marginTop: 16 }} xs={10} md={10} lg={12} xl={12}>
									<Typography.Text className="text label">Điện thoại:</Typography.Text>
								</Col>
								<Col xs={14} md={14} lg={12} xl={12}>
									<Space>
										<MessageIcon style={{ fontSize: 24 }} />
										<Typography.Text className="text">info@247express.vn</Typography.Text>
									</Space>
								</Col>
								<Col xs={10} md={10} lg={12} xl={12}>
									<Space>
										<PhoneIcon />
										<Typography.Text className="text">1900 6980</Typography.Text>
									</Space>
								</Col>
							</Row>
						</Col>
						<Col xs={24} md={24} lg={24} xl={8}>
							<Row gutter={[16, 24]} justify="space-around">
								{
									<Col key={infoMenu.title} xs={24} md={24} lg={infoMenu.size} xl={infoMenu.size}>
										<Row gutter={[16, 16]}>
											<Col xs={24} md={24} lg={24} xl={24}>
												<Typography.Text className="text title">{infoMenu.title}</Typography.Text>
											</Col>
											{infoMenu?.children?.map((e) => (
												<Col key={e.Name} xs={24} md={24} lg={24} xl={24}>
													<Typography.Link href={e.Url} className="Link menu">
														{e.Name}
													</Typography.Link>
												</Col>
											))}
										</Row>
									</Col>
								}
								{
									<Col key={helpMenu.title} xs={24} md={24} lg={helpMenu.size} xl={helpMenu.size}>
										<Row gutter={[16, 16]}>
											<Col xs={24} md={24} lg={24} xl={24}>
												<Typography.Text className="text title">{helpMenu.title}</Typography.Text>
											</Col>
											{helpMenu?.children?.map((e) => (
												<Col key={e.Name} xs={24} md={24} lg={24} xl={24}>
													<Typography.Link href={e.Url} className="Link menu">
														{e.Name}
													</Typography.Link>
												</Col>
											))}
										</Row>
									</Col>
								}
							</Row>
						</Col>
						<Col xs={24} md={24} lg={24} xl={8}>
							<Row justify="space-between">
								<Col xs={24} md={24} lg={12} xl={12}>
									<Row gutter={[8, 24]}>
										<Col xs={12} md={12} lg={24} xl={24}>
											<Typography.Text className="text contact">Liên hệ ngay</Typography.Text>
											<br />
											<Typography.Text className="text phone">19006980</Typography.Text>
											<br />
										</Col>
										<Col xs={12} md={12} lg={24} xl={24}>
											<Typography.Text className="text contact">
												Kết nối với chúng tôi
											</Typography.Text>
											<br />
											<Space style={{ marginTop: 15 }}>
												<FacebookIcon
													className="icon"
													onClick={() =>
														window.open('https://www.facebook.com/247post.vn', '_blank')
													}
												/>
												<YoutubeIcon
													className="icon"
													onClick={() =>
														window.open(
															'https://www.youtube.com/channel/UC2cFeTMDhfi1vtXNDSYBNrQ',
															'_blank'
														)
													}
												/>
												<ZaloIcon
													className="icon"
													onClick={() =>
														window.open('https://zalo.me/2150952493041875123', '_blank')
													}
												/>
											</Space>
										</Col>
									</Row>
								</Col>
								<Col
									xs={24}
									md={24}
									lg={12}
									xl={12}
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-start',
									}}
								>
									<Typography.Text className="text contact">Download App</Typography.Text>
									{/* eslint-disable-next-line @next/next/no-img-element, @next/next/no-img-element */}
									<a aria-label={"247Express-Nhanh hơn bạn nghĩ"} href="https://apps.apple.com/vn/app/247express/id1438093138?l=vi">
										<img
											style={{ height: 38, objectFit: 'contain' }}
											className="download"
											src="/images/apple.png"
											alt={BASE_CONFIG.PAGE_NAME}
										/>
									</a>
									{/* eslint-disable-next-line @next/next/no-img-element, @next/next/no-img-element */}
									<a aria-label={"247Express-Nhanh hơn bạn nghĩ"} href="https://play.google.com/store/apps/details?id=com.holdings.a247express">
										<img
											style={{ height: 38, objectFit: 'contain' }}
											className="download"
											src="/images/android.png"
											alt={BASE_CONFIG.PAGE_NAME}
										/>
									</a>
									<Row style={{ width: '100%', marginTop: 8 }} justify="start">
										<Col>
											{/* eslint-disable-next-line @next/next/no-img-element */}
											<img
												style={{ height: 128, objectFit: 'contain' }}
												src={`/images/qrcode_download.png`}
												alt="Download app 247Express"
											/>

										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
					<Col span={24} className="copyright">
						<Typography.Text className="text copyright">
							Giấy chứng nhận Đăng kí Kinh doanh số: 0304043037 do Sở kế hoạch đầu tư Thành phố Hồ
							Chí Minh cấp ngày: 20/10/2005
						</Typography.Text>
					</Col>
				</Row>
			</div>
			{LiveChatComponent}
			<div className="footer-last" />
		</div>
	)
}

export default Footer
