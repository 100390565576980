import {
	BellOutlined,
	CloseOutlined,
	EllipsisOutlined,
	GlobalOutlined,
	LeftOutlined,
	SearchOutlined,
} from '@ant-design/icons'
import { useDebounce } from '@react-hook/debounce'
import { Avatar, Collapse, Drawer, Input, Modal, Popover, Tooltip } from 'antd'
import Link from 'next/link'
import Router, { useRouter } from 'next/router'
import { useContext, useEffect, useMemo, useState } from 'react'
import BASE_CONFIG from 'src/configs/base'
import MENU_CONFIGS from 'src/configs/menu'
import useLanguages from 'src/hooks/useLanguages'
import { GlobalContext } from 'src/pages/_app'
import newsServices from 'src/services/news.service'
import notificationService from 'src/services/notification.service'
import { preparePath, stripQueryStringAndHashFromPath } from 'src/utils/common'
import MobileMenu from '../MobileMenu'
import NotificationsList from '../NotificationsList'
import UserOptions from '../UserOptions'
const { Panel } = Collapse

const Header = () => {
	const { toVietnamese } = useLanguages()

	const asPath = preparePath(stripQueryStringAndHashFromPath(useRouter().asPath))
	const [lang, setLang] = useState(BASE_CONFIG.LANGS[0])
	const [mbVisible, setMbVisible] = useState(false)
	const [mbOptionVisible, setMbOptionVisible] = useState(false)
	const [searchVisible, setSearchVisible] = useState(0)
	const [searchRecommendations, setSearchRecommendations] = useState(null)
	const [search, setSearch] = useDebounce(null, 869)
	const [ggTrans, setGgTrans] = useState(false)
	const [notifVisible, setNotifVisible] = useState(false)
	const [notifList, setNotifList] = useState([])
	const [notifLoading, setNotifLoading] = useState(true)
	const [isTranslate, setIsTranslate] = useState(false)

	const { user, menuItems } = useContext(GlobalContext)
	const isLogged = !!user

	const getFiltererdMenu = (item) => {
		if (item.Children?.length > 0)
			item.Children = item.Children.map((e) => getFiltererdMenu(e)).filter((e) => !!e)
		if (item.IsPrivated) return undefined

		return item
	}

	const filteredMenuItems = useMemo(() => {
		if (!user) return menuItems.map((e) => getFiltererdMenu(e)).filter((e) => !!e)
		else return menuItems
	}, [user, menuItems])

	const handleChangeLange = (lang) => {
		setLang(lang)
		toVietnamese('es')
	}

	const toggleMbMenu = () => {
		setMbVisible(!mbVisible)
	}

	const toggleMbOption = () => {
		setMbOptionVisible(!mbOptionVisible)
	}

	const handleSearchChange = ({ target: { value } }) => {
		if (value && value != '') {
			setSearch(value)
		} else {
			setSearch(null)
			setSearchRecommendations(null)
		}
	}

	const handleSearchEnter = () => {
		if (searchRecommendations?.length > 0) {
			Router.push(`/tin-tuc?q=${search.trim()}`)
		}
	}

	const Languages = (
		<div id="LanguageSelection">
			<ul>
				{BASE_CONFIG.LANGS.map((l) => (
					<li
						onClick={() => handleChangeLange(l)}
						key={l.value}
						className={(lang.value === l.value && 'Active') || null}
					>
						{l.label}
					</li>
				))}
			</ul>
		</div>
	)

	const renderSubMenu = (menu, level = 2) => {
		return (
			<ul className="HeaderSubMenu">
				{menu?.map((s) => {
					const preparedUrl = preparePath(s.Url)
					const isDisabled = MENU_CONFIGS.DISABLED_ITEMS_WHITELIST.includes(s.Url)
					const isChildActive = !!s.Children?.find((i) => i.Url == asPath)
					const isTitleActive =
						(preparedUrl.split('/')[level] || {}) == (asPath.split('/')[level] || {}) ||
						preparedUrl == asPath ||
						!!isChildActive

					return (
						<li key={s.Key}>
							{isDisabled ? (
								<span
									className={
										isTitleActive
											? 'disabled__menu__item SubMenuItemActive'
											: 'disabled__menu__item'
									}
								>
									{s.Name}
								</span>
							) : (
								<Link href={s.Url || '/'}>
									<a
										aria-label={'247Express-Nhanh hơn bạn nghĩ'}
										className={isTitleActive ? 'SubMenuItemActive' : ''}
									>
										{s.Name}
									</a>
								</Link>
							)}
							{s.Children?.length > 0 && renderSubMenu(s.Childrenm, level + 1)}
						</li>
					)
				})}
			</ul>
		)
	}

	const MENU_RESPONSE_BREAKPOINT = useMemo(() => {
		return isLogged ? 4 : 4
	}, [isLogged])

	// setup google translate
	const googleTranslateElementInit = () => {
		new window.google.translate.TranslateElement(
			{
				pageLanguage: 'vi',
				includedLanguages: 'en,vi', // include this for selected languages
			},
			'google_translate_element'
		)
	}
	//script Viettel CRM
	function loadJsAsync(src, callback) {
		let s = document.createElement('script')
		s.type = 'text/javascript'
		s.src = src
		s.addEventListener(
			'load',
			function (e) {
				callback(null, e)
			},
			false
		)
		let head = document.getElementsByTagName('head')[0]
		head.appendChild(s)
	}
	// setup google translate
	useEffect(() => {
		if (document.location.href.indexOf('in-don-hang') == -1) {
			// var elm = document.getElementById('ipcc_chat_iframe')
			// if (!elm) {
			// 	let s = document.createElement('script')
			// 	s.className = 'viettel_live_chat'
			// 	s.id = 'vietteltelecom'
			// 	s.type = 'text/javascript'
			// 	s.src = 'https://cc.vietteltelecom.vn/chat-client/assets/js/IpccChat.js'
			// 	s.addEventListener(
			// 		'load',
			// 		function () {
			// 			let IpccChat = {
			// 				realmName: 'dvkh',
			// 				domain: '5805a0da-3f85-4366-99b9-854b44f6d178',
			// 				url: 'https://cc.vietteltelecom.vn/chat-client',
			// 			}
			// 			embedIpccChat(IpccChat)
			// 		},
			// 		false
			// 	)
			// 	let head = document.getElementsByTagName('head')[0]
			// 	head.appendChild(s)
			// }
		} else {
			// var ele = document.getElementById('vietteltelecom')
			// if (ele) {
			// 	ele.remove()
			// }
		}
		// google translate
		if (!ggTrans) return

		var addScript = document.createElement('script')
		addScript.setAttribute(
			'src',
			'//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
		)
		if (!isTranslate) {
			document.body.appendChild(addScript)
			setIsTranslate(true)
		}

		window.googleTranslateElementInit = googleTranslateElementInit
	}, [ggTrans])

	// call search API
	useEffect(() => {
		if (!search) return

		newsServices
			.searchPosts(search.trim())
			.then((resp) => {
				setSearchRecommendations(resp || [])
			})
			.catch(console.log)
	}, [search])

	useEffect(() => {
		setMbVisible(false)
	}, [asPath])

	useEffect(() => {
		if (!searchVisible) setSearchRecommendations(null)
	}, [searchVisible])

	// get latest notifications
	useEffect(() => {
		if (!notifVisible || !user) return

		// notificationService
		// 	.getNotifications({ username: user.customerID, pageSize: 5 })
		// 	.then((response) => {
		// 		if (!response.isError) {
		// 			// setNotifList(response.data || [])
		// 		}
		// 	})
		// 	.catch(console.log)
		// 	.finally(() => setNotifLoading(false))
	}, [user, notifVisible])

	return (
		<div className="HeaderSticky">
			<div className="Header">
				{user && (
					<Modal
						zIndex={100}
						onCancel={toggleMbOption}
						getContainer=".Header"
						open={mbOptionVisible}
						footer={null}
					>
						<div className="mb__user__options">
							<UserOptions />
						</div>
					</Modal>
				)}
				{/* {ggTrans && <div id="google_translate_element"></div>} */}
				<Modal
					className="modal-trans"
					open={ggTrans}
					title="Chọn ngôn ngữ"
					onCancel={() => setGgTrans(false)}
					footer={false}
				>
					<div id="google_translate_element"></div>
				</Modal>
				<Drawer
					zIndex={99}
					extra={
						isLogged ? (
							<div className="HeaderUser">
								<div onClick={toggleMbOption} style={{ display: 'flex', alignItems: 'center' }}>
									<Avatar
										shape="square"
										style={{ flexShrink: 0 }}
										src={
											// eslint-disable-next-line jsx-a11y/alt-text, @next/next/no-img-element
											<img
												src={user.Avatar || '/images/default_avatar.jpeg'}
												style={{ width: '100%' }}
												alt={user.Username}
											/>
										}
									/>
									<span
										className="HeaderUser-DisplayName"
										style={{ marginLeft: 8, fontWeight: 500 }}
									>
										{user.displayName}
									</span>
								</div>
								{/* <Badge count={1} offset={[-8, 4]} size="small">
								<Tooltip title="Thông báo">
									<BellOutlined
										style={{
											fontSize: 20,
											opacity: 0.8,
											transform: 'translateY(3px)',
											marginLeft: 24,
											marginRight: 6,
											cursor: 'pointer',
										}}
									/>
								</Tooltip>
							</Badge> */}
							</div>
						) : (
							<div className="MobileHeaderLogin">
								<Link href={MENU_CONFIGS.SIGN_IN_REDIRECT}>Đăng nhập</Link>
								<span className="MenuHeader-Divider" />
								<Link href={MENU_CONFIGS.SIGN_UP_REDIRECT}>Đăng ký</Link>
							</div>
						)
					}
					className="MobileHeader-Wrapper"
					getContainer=".Header"
					onClose={toggleMbMenu}
					title=""
					placement={'left'}
					width="75%"
					open={mbVisible}
					closeIcon={user ? undefined : <LeftOutlined style={{ transform: 'translateX(-50%)' }} />}
					headerStyle={
						user ? { flexDirection: 'row-reverse', justifyContent: 'space-between' } : {}
					}
				>
					<MobileMenu items={filteredMenuItems} language={Languages} lang={lang} />
				</Drawer>
				<div className="container HeaderContainer">
					<button
						onClick={toggleMbMenu}
						aria-label="HeaderMobileBtn"
						name="HeaderMobileBtn"
						className="HeaderMobileBtn mb"
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								x="2.03711"
								y="18.2864"
								width="10.9912"
								height="1.78712"
								rx="0.893561"
								fill="#181C32"
							/>
							<rect
								x="2.03711"
								y="11.1064"
								width="19.9635"
								height="1.78712"
								rx="0.893561"
								fill="#181C32"
							/>
							<rect
								x="2.03711"
								y="3.92627"
								width="14.9815"
								height="1.78712"
								rx="0.893561"
								fill="#181C32"
							/>
						</svg>
					</button>
					<Link href="/">
						<img className="HeaderLogo" src="/images/logo-f.png" alt={BASE_CONFIG.PAGE_NAME} />
					</Link>

					<div className="HeaderMenuWrapper">
						<ul className="HeaderMenuList">
							{
								<li key={'home_menu'} className={'/' === asPath ? 'MenuItemActive' : ''}>
									<Link href={'/'}>{'Trang chủ'}</Link>
								</li>
							}
							{filteredMenuItems.map((e, index) => {
								const preparedUrl = preparePath(e.Url)

								const isChildActive = !!e.Children?.find((i) => i.Url == asPath)
								const isTitleActive =
									(preparedUrl.split('/')[1] || {}) == (asPath.split('/')[1] || {}) ||
									preparedUrl == asPath ||
									!!isChildActive

								let className = isTitleActive ? 'MenuItemActive' : ''
								className = index > MENU_RESPONSE_BREAKPOINT ? className + ' HideOnHd' : className

								const isDisabled = MENU_CONFIGS.DISABLED_ITEMS_WHITELIST.includes(e.Url)

								if (preparedUrl.split('/')[1] == 'dashboard') {
									if (asPath.split('/')[1].includes('don-hang')) {
										className = 'MenuItemActive'
									}
								}

								return (
									<li key={e.Key} className={className}>
										{isDisabled ? (
											<span
												className={
													isTitleActive
														? 'disabled__menu__item SubMenuItemActive'
														: 'disabled__menu__item'
												}
											>
												{e.Name}
											</span>
										) : (
											<Link href={e.Url || '/'}>{e.Name}</Link>
										)}
										{e.Children?.length > 0 && renderSubMenu(e.Children)}
									</li>
								)
							})}
							{filteredMenuItems.length > MENU_RESPONSE_BREAKPOINT && (
								<li className="mobile__items__extend">
									<EllipsisOutlined
										style={{ fontWeight: 600, fontSize: 20, transform: 'translateY(1px)' }}
									/>
									<ul className="HeaderSubMenu">
										{filteredMenuItems
											// .filter((e) => !e.hideOnHeader)
											.slice(MENU_RESPONSE_BREAKPOINT + 1)
											.map((s) => {
												const preparedUrl = preparePath(s.Url)

												const isDisabled = MENU_CONFIGS.DISABLED_ITEMS_WHITELIST.includes(s.Url)
												const isChildActive = !!s.Children?.find((i) => i.Url == asPath)
												const isTitleActive =
													(preparedUrl.split('/')[1] || {}) == (asPath.split('/')[1] || {}) ||
													preparedUrl == asPath ||
													!!isChildActive

												return (
													<li key={s.Key}>
														{isDisabled ? (
															<span
																className={
																	isTitleActive
																		? 'disabled__menu__item SubMenuItemActive'
																		: 'disabled__menu__item'
																}
															>
																{s.Name}
															</span>
														) : (
															<Link href={s.Url || '/'}>{s.Name}</Link>
														)}
														{s.Children?.length > 0 && renderSubMenu(s.Children)}
													</li>
												)
											})}
									</ul>
								</li>
							)}
						</ul>
					</div>

					<div className="HeaderHotline">
						<a aria-label={'247Express-Nhanh hơn bạn nghĩ'} href={`tel:${BASE_CONFIG.HOTLINE}`}>
							{/* eslint-disable-next-line @next/next/no-img-element */}
							<img src="/icons/hotline.png" alt={BASE_CONFIG.HOTLINE} />
							<span>{BASE_CONFIG.HOTLINE}</span>
						</a>
					</div>

					<div className="HeaderLang">
						{/* eslint-disable-next-line @next/next/no-img-element */}
						{/* <img src={lang.icon} alt={lang.label} />
						<Popover style={{ padding: 0 }} content={Languages}>
							<span className="LangValue">
								<span className="LangValue-Label">{lang.value}</span>
								<DownOutlined
									style={{ marginLeft: 8, fontSize: 12, transform: 'translateY(-1px)' }}
								/>
							</span>
						</Popover> */}
						<GlobalOutlined
							style={{ fontSize: 18, color: ggTrans ? '#cc0000' : null }}
							onClick={() => setGgTrans(!ggTrans)}
						/>
					</div>

					{isLogged ? (
						<div className="HeaderUser">
							<Popover
								getPopupContainer={() => document.querySelector('.HeaderUser')}
								content={<UserOptions />}
								trigger="click"
							>
								<Avatar
									shape="square"
									src={
										// eslint-disable-next-line jsx-a11y/alt-text, @next/next/no-img-element
										<img
											src={user.avatarUrl || '/images/default_avatar.jpeg'}
											style={{ width: '100%' }}
											alt={user.Username}
										/>
									}
								/>
							</Popover>
							<span id="header-notif">
								<Popover
									getPopupContainer={() => document.querySelector('#header-notif')}
									content={<NotificationsList data={notifList} loading={notifLoading} />}
									onOpenChange={(vis) => {
										setNotifVisible(vis)
									}}
									trigger="click"
									placement="bottomLeft"
								>
									<BellOutlined
										style={{
											fontSize: 20,
											opacity: 0.8,
											transform: 'translateY(3px)',
											marginLeft: 24,
											marginRight: 6,
											cursor: 'pointer',
										}}
									/>
								</Popover>
							</span>
						</div>
					) : (
						<div className="HeaderLogin">
							<Link href={MENU_CONFIGS.SIGN_IN_REDIRECT}>Đăng nhập</Link>
							<span className="HeaderLogin-Divider" />
							<Link href={MENU_CONFIGS.SIGN_UP_REDIRECT}>Đăng ký</Link>
						</div>
					)}

					<div className="HeaderSearch">
						<Tooltip title="Tìm kiếm">
							<SearchOutlined
								style={{
									fontSize: 20,
									transform: 'translateY(2px)',
									cursor: 'pointer',
								}}
								onClick={() => setSearchVisible(!searchVisible ? 1 : 0)}
							/>
						</Tooltip>
					</div>
				</div>
				<div className={'container header__search'}>
					<Collapse activeKey={searchVisible}>
						<Panel header={null} key={1}>
							<div className="search-container">
								<Input
									style={{
										zIndex: 11,
									}}
									placeholder="Tìm kiếm"
									onChange={handleSearchChange}
									onPressEnter={handleSearchEnter}
									prefix={<SearchOutlined style={{ fontSize: 18 }} />}
									allowClear={{
										clearIcon: (
											<CloseOutlined
												style={{
													fontSize: 18,
													color: 'var(--my-text-color)',
													transform: 'translateY(2px)',
												}}
											/>
										),
									}}
								/>
								{searchRecommendations &&
									(searchRecommendations.length > 0 ? (
										<ul className="search-recommendations">
											{searchRecommendations.map((e) => (
												<li key={e._Id}>
													<Link href={`/tin-tuc/${e.CategorySlug}/${e.Url}/${e.Id}`}>{e.Name}</Link>
												</li>
											))}
										</ul>
									) : (
										<ul className="search-recommendations">
											<li>
												<a
													href="#"
													style={{
														color: 'var(--my-red)',
														textAlign: 'center',
													}}
													className="no__data"
												>
													Không tìm thấy dữ liệu!
												</a>
											</li>
										</ul>
									))}
							</div>
						</Panel>
					</Collapse>
				</div>
			</div>
		</div>
	)
}

export default Header
