import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { Button, Input, Form, Typography, Tooltip, Col, Row, Upload, message, Popconfirm, Image, Card } from "antd";
import { FilePdfOutlined, CloseOutlined, SendOutlined, PaperClipOutlined, LineOutlined, FileExcelOutlined, FileWordOutlined, FileTextOutlined, DownloadOutlined } from "@ant-design/icons";
import APIConstants, { CRM_SOCKET } from '../../configs/API';
import * as signalR from "@microsoft/signalr";
import axios from "axios";
import moment from "moment";

const ChatMessage = React.memo(({ key, msg, customerId }) => {
  return <div
    key={key}
    style={{
      textAlign: msg.senderId === customerId || customerId == null || customerId == undefined ? "right" : "left",
      marginBottom: 8,
    }}
  >
    {(msg.senderId !== customerId && customerId != null) &&
      <img src='/images/support-icon.png' style={{ width: 48, height: 48, borderRadius: 20, margin: "10px 2px" }} />
    }
    <div
      style={{
        display: "inline-flex",
        padding: "8px 8px",
        borderRadius: 8,
        backgroundColor: (msg.senderId !== customerId && customerId !== null) ? "#fff" : "#b3dcff",
        maxWidth: "80%",
        
      }}
    >
      <Row>
        <Col span={24} style={{ display: msg.senderId !== customerId ? "block" : "none" }}>
          <Typography.Title level={5}>
            {msg.senderId !== customerId ? msg.senderId : ""}
          </Typography.Title>
        </Col>
        <Col span={24} style={{textAlign:"left"}}>
          {/* <Typography.Text>{msg.content?.indexOf("https://") > -1 ? <a href={msg.content} target="_blank" rel="noopener noreferrer"><PaperClipOutlined /> {msg.content}</a> : msg.content}</Typography.Text> */}
          <MessageContentItem content={msg.content} />
        </Col>
      </Row>
    </div>
    {/* <div style={{ fontSize: 12, color: "#888", marginTop: 4 }}>{msg.time}</div> */}
  </div>
});
ChatMessage.displayName = 'ChatMessage';

const MessageContentItem = React.memo(({ content }) => {
  if (content.text && (!content.attachments || content.attachments.length == 0)) {
    return <Typography.Text style={{textAlign:"left"}}>{content.text}</Typography.Text>
  } else {
    if (content.attachments) {
      return content.attachments.map((item, index) => (
        <FileViewer key={content.url} text={content.text} fileUrl={item.url} attachmentType={item.attachmentType} fileName={item.fileName} fileType={item.fileType} />
      ))
    } else {
      return <Typography.Text style={{textAlign:"left"}}>{content.text}</Typography.Text>
    }
  }
})

MessageContentItem.displayName = 'MessageContentItem';

const FileViewer = ({ fileUrl, fileType, fileName, attachmentType, text }) => {
  const getDisplayFileName = (fileName) => {
    if (!fileName) return '';
    let ext = fileName.split('.').pop();
    let fileNameWithoutExt = fileName.split('.').slice(0, -1).join('.');
    return `${fileNameWithoutExt.length > 18 ? fileNameWithoutExt.slice(0, 18) + '..' : fileNameWithoutExt}.${ext}`;
  };

  // bỏ switch-case, thay bằng if else
  if (attachmentType.indexOf("image") > -1) {
    return <Image src={fileUrl} preview={
      {
        title: "Xem trước"
      }
    } width="100%" />;
  } else if (attachmentType.indexOf("video") > -1) {
    return <video controls width="100%"><source src={fileUrl} type="video/mp4" />Your browser does not support video.</video>;
  } else if (attachmentType.indexOf("audio") > -1) {
    return <audio style={{ width: 240 }} controls><source src={fileUrl} type="audio/mp3" />Your browser does not support audio.</audio>;
  } else if (attachmentType.indexOf("pdf") > -1) {
    // return <iframe src={fileUrl} width="100%" height="500px" title={fileName}></iframe>;
    <Row>
      <Col span={6}><FilePdfOutlined className="icon-file pdf-icon"></FilePdfOutlined></Col>
      <Col span={18}>
        <Typography.Text className="link-file" onClick={() => window.open(fileUrl, '_blank')}>{getDisplayFileName(fileName)}</Typography.Text>
        <Typography.Paragraph className="size-file">{""} <DownloadOutlined onClick={() => window.open(fileUrl, '_blank')} className="download-icon"></DownloadOutlined></Typography.Paragraph>
      </Col>
    </Row>
  } else if (attachmentType.indexOf("link") > -1) {
    if (text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const linkedMessage = text.replace(urlRegex, (url) => `<a target="_blank" href="${url}">${url}</a>`);
      return <p style={{wordWrap:'break-word', textAlign:"left"}} dangerouslySetInnerHTML={{ __html: linkedMessage }}></p>;
    } else {
      return <a style={{wordWrap:'break-word', textAlign:"left"}} href={fileUrl} target="_blank" rel="noopener noreferrer">{fileUrl}</a>;
    }
  }
  else if (attachmentType.indexOf("application") > -1 || attachmentType.indexOf("file") > -1 || attachmentType.indexOf("text/csv") > -1) {
    var icon = <></>;
    if (fileType === "xlsx" || fileType === "xls" || fileType === "csv") {
      icon = <FileExcelOutlined className="icon-file excel-icon"></FileExcelOutlined>;
    } else if (fileType === "doc" || fileType === "docx") {
      icon = <FileWordOutlined className="icon-file doc-icon"></FileWordOutlined>
    } else if (fileType === "pdf") {
      icon = <FilePdfOutlined className="icon-file pdf-icon"></FilePdfOutlined>
    } else {
      icon = <FileTextOutlined className="icon-file file-icon"></FileTextOutlined>;
    }
    return <div style={{ width: "100%" }}>
      <Row style={{ width: "100%" }}>
        <Col span={8}> {icon}</Col>
        <Col span={16}>
       
          <Typography.Text className="link-file" onClick={() => window.open(fileUrl, '_blank')}>{getDisplayFileName(fileName)}</Typography.Text>
          <Typography.Paragraph className="size-file">{""} <DownloadOutlined onClick={() => window.open(fileUrl, '_blank')} className="download-icon"></DownloadOutlined></Typography.Paragraph>
        </Col>
      </Row>
    </div>
  } else {
    return <Row style={{width:"100%"}}>
      <Col span={8}><FileTextOutlined className="icon-file file-icon"></FileTextOutlined></Col>
      <Col span={16}>
        <Typography.Text className="link-file" onClick={() => window.open(fileUrl, '_blank')}>{getDisplayFileName(fileName)}</Typography.Text>
        <Typography.Paragraph className="size-file">{""} <DownloadOutlined onClick={() => window.open(fileUrl, '_blank')} className="download-icon"></DownloadOutlined></Typography.Paragraph>
      </Col>
    </Row>
  }
};
FileViewer.displayName = 'FileViewer';

const LiveChat = React.memo(() => {
  const [visible, setVisible] = useState(false);
  const [chatStarted, setChatStarted] = useState(false);
  const [form] = Form.useForm();
  const divChatRef = useRef(null);
  const divChatContainerRef = useRef(null);
  const [customerId, setCustomerId] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const initialMessages = useMemo(() => [
    {
      "messageId": "",
      "conversationId": null,
      "senderId": "247Express",
      "senderName": null,
      "content": {
        text: "Kính chào Quý khách! Mời Quý khách đặt câu hỏi để 247Express tiếp nhận và hỗ trợ."
      },
      "timestamp": ""
    }]
    , []);
  const [messages, setMessages] = useState([]);
  const [connection, setConnection] = useState(null);
  const [chatUser, setChatUser] = useState({});
  const messageList = useMemo(() => {
    return messages.map((msg, index) => (
      <ChatMessage key={index} msg={msg} customerId={customerId} />
    ));
  }, [messages]);

  useEffect(() => {
    var chatUserDataStr = window.localStorage.getItem('chatUser')
    var chatUserData = JSON.parse(chatUserDataStr);
    if (chatUserData) {
      setCustomerId(chatUserData.phone);
      setChatUser(chatUserData);
      if (chatUserData.isChatted && moment(chatUserData.expiredDate) > moment()) {
        setChatStarted(true);
        setVisible(true);
      }
    } else {
      var customerData = window.localStorage.getItem('user');
      var customer = JSON.parse(customerData);
      if (customer) {
        setCustomerId(customer.phone);
        form.setFieldValue('phone', customer.phone);
        form.setFieldValue('name', customer.clientName)
      }
    }
  }, [])
  useEffect(() => {
    if (connection) {
      connection.on("ReceiveMessageAsync", (message) => {
        setMessages((prev) => [...prev, message]);
      });
    }
  }, [connection])
  const initConnection = (message) => {
    if (connection) return;

    var newConnection = new signalR.HubConnectionBuilder()
      .withUrl(APIConstants.CRM_SOCKET_LIVE_CHAT_HUB, {
        skipNegotiation: true, // Sử dụng WebSocket trực tiếp
        transport: signalR.HttpTransportType.WebSockets, // Chỉ dùng WebSocket
      })
      .withAutomaticReconnect()
      .build();

    // Handle received messages
    //  newConnection.on("ReceiveMessageAsync", (message) => {
    //   setMessages((prev) => [...prev, message]);
    // });
    newConnection.start().then(() => {
      console.log("Connected");
      if (newConnection.state === 'Connected') {
        if (chatUser) {
          if (chatUser.phone) {
            console.log(chatUser)
            newConnection.invoke("RegisterAnonymous", {
              name: chatUser.name,
              phone: chatUser.phone
            });
          } else {
            var chatUserDataStr = window.localStorage.getItem('chatUser')
            var chatUserData = JSON.parse(chatUserDataStr);
            newConnection.invoke("RegisterAnonymous", {
              name: chatUserData.name,
              phone: chatUserData.phone
            });
            setChatUser(chatUserData);
            setCustomerId(chatUserData.phone);
          }
          setConnection(newConnection);
          setChatStarted(true);
        } else {
          setConnection(null);
          setChatStarted(false);
        }

        // if has message
        if (message) {
          const model = {
            phone: message.senderId,
            name: message.name,
            message: message.content.text,
            fileUrl: null,
            sentTime: null
          };
          console.log(model)
          newConnection.invoke("SendMessage", model);
        }
      }
    });
  }
  useEffect(() => {
    if (chatStarted === true) {
      if (!connection) {
        initConnection();
      }
    }
  }, [chatStarted])
  useEffect(() => {
    getHistory(customerId);
  }, [customerId])
  const getHistory = (phone) => {
    if (phone) {
      axios.get(`${CRM_SOCKET}/api/messages/${phone}?pageSize=${50}&pageNumber=${pageIndex} `).then(res => {
        console.log(res.data.data)
        const messagesRes = res.data.data.items?.reverse();
        if (messagesRes && messagesRes.length > 0) {
          if (pageIndex === 1) {
            //setMessages(messagesRes);
            setMessages([...messagesRes, ...messages]);
            setMaxPage(res.data.data.totalPages);
            setTimeout(() => {
              scrollToBottom();
            }, 500)
          } else {
            setMessages([...messagesRes, ...messages]);
          }
          if (pageIndex <= res.data.data.totalPages) {
            setPageIndex(pageIndex + 1);
          }
        }
      })
    }
  }
  const scrollToBottom = () => {
    if (divChatRef.current) {
      divChatRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleScrollToTop = () => {
    const container = divChatContainerRef.current;
    if (container) {
      const { scrollTop } = container;
      if (scrollTop === 0) {
        if (pageIndex <= maxPage) {
          getHistory(customerId);
        }
      }
    }
  };
  const disconnect = () => {
    if (connection) {
      connection.stop();
      setConnection(null);
    }
    window.localStorage.removeItem('chatUser');
    setVisible(false);
    setChatStarted(false);
    setChatUser({});
    setMessages([]);
    setPageIndex(1);
    setCustomerId(null);
  }
  const sendMessage = useCallback(() => {
    if (!connection || !chatUser) {
      console.log("Error: Not connected");
      initConnection();
      sendMessage();
      return;
    }
    try {
      var message = form.getFieldValue('content');
      let isHyperLinkMessage = message.includes("http://") || message.includes("https://");
      if (message.trim() === '') return
      const model = {
        phone: chatUser.phone,
        name: chatUser.name,
        message: message.trim(),
        fileUrl: null,
        sentTime: null
      };
      if (connection && connection.state !== 'Connected') {

        connection.start().then(() => {
          connection.invoke("SendMessage", model);
          setMessages((prev) => [
            ...prev,
            {
              senderId: chatUser.phone, content: {
                text: message,
                attachments: isHyperLinkMessage ? [{ url: message,
                  "fileType": "link",
                  "attachmentType": "link"
                }] : []
              }
            },
          ]);
        })
      } else {
        connection.invoke("SendMessage", model);
        setMessages((prev) => [
          ...prev,
          {
            senderId: chatUser.phone, content: {
              text: message,
              attachments: isHyperLinkMessage ? [{ url: message,
                "fileType": "link",
                "attachmentType": "link"
              }] : []
            }
          },
        ]);
      }
      form.setFieldValue('content', '');
      setTimeout(() => {
        scrollToBottom();
      }, 300)

    } catch (err) {
      console.error(err);
    }
  })
  const startChat = (values) => {
    let chatUserData = {
      name: values.name,
      phone: values.phone,
      isChatted: true,
      expiredDate: moment().add(1, 'hours')
    };
    setCustomerId(values.phone);

    window.localStorage.setItem('chatUser', JSON.stringify(chatUserData))
    setChatUser(chatUserData);
    setTimeout(() => {
      if (values.message) {
        let message = {
          senderId: values.phone, content: {
            text: values.message
          }, name: values.name
        };
        setMessages((prev) => [
          ...prev,
          initialMessages[0],
          message,
        ]);
        initConnection(message);
      }
      else {
        initConnection(null);
      }

      form.setFieldValue('message', '');
    }, 500)

  };

  const props = {
    name: 'formFile',
    action: APIConstants.CRM_SOCKET_LIVE_CHAT_UPLOAD_FILE,
    headers: {
      folder: 'CHAT'
    },
    showUploadList: false,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        //console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        var chatUser = JSON.parse(window.localStorage.getItem('chatUser'));
        if(info.file.response.isError === true) {
          message.error("Lỗi tải tệp tin: " + info.file.response.errorMessage);
          return;
        }
        message.success(`${info.file.name} file uploaded successfully`);
        var fileUrl = info.file.response.data.fileUrl;
        const model = {
          phone: chatUser.phone,
          name: chatUser.name,
          message: "",
          fileUrl: fileUrl,
          sentTime: null,
          fileName: info.file.response.data.fileName,
          fileType: info.file.response.data.type,
          fileSize: info.file.response.data.fileSize,
          thumbnailUrl: info.file.response.data.thumbnail,
          description: info.file.response.data.description,
          attachmentType: info.file.response.data.typeConvert,
        };
        console.log(model)
        if (connection && connection.state !== 'Connected') {
          connection.start().then(() => {
            connection.invoke("SendMessage", model);
            setMessages((prev) => [
              ...prev,
              {
                senderId: chatUser.phone, content: {
                  "text": "",
                  "attachments": [
                    {
                      "fileName": model.fileName,
                      "url": model.fileUrl,
                      "thumbnail": model.thumbnailUrl,
                      "fileType": model.fileType,
                      "attachmentType": model.attachmentType,
                      "description": model.description
                    }
                  ]
                }
              },
            ]);
          })
        } else {
          connection.invoke("SendMessage", model);
          setMessages((prev) => [
            ...prev,
            {
              senderId: chatUser.phone, content: {
                "text": "",
                "attachments": [
                  {
                    "fileName": model.fileName,
                    "url": model.fileUrl,
                    "thumbnail": model.thumbnailUrl,
                    "fileType": model.fileType,
                    "attachmentType": model.attachmentType,
                    "description": model.description
                  }
                ]
              }
            },
          ]);
        }

        setTimeout(() => {
          scrollToBottom();
        }, 300)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <>
      {/* Floating Chat Button */}
      <div className="livechat">
        < img src={'/images/support-icon.png'}
          onClick={() => setVisible(!visible)}
          style={{
            position: "fixed",
            bottom: 20,
            right: 20,
            width: 80,
            height: 80,
            fontSize: 24,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            zIndex: 1000,
            padding: 10,
            borderRadius: 40
          }}
        //className="bubble-float-icon"
        />
        {/* Chat Box */}
        {visible && (
          <>
            <div
              style={{
                position: "fixed",
                bottom: 100,
                right: 20,
                width: 380,
                backgroundColor: "white",
                borderRadius: 10,
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                zIndex: 1000,
              }}
            >
              {/* Chat Header */}
              <div
                className="form-input-customer-info"
              >
                <Typography.Text style={{ color: "white", fontWeight: "bold", fontSize: 18 }}>
                  < img src={'/images/support-icon.png'}></img> {chatStarted ? "247Express" : 'Chat vs nhân viên tư vấn'}
                </Typography.Text>
                <Popconfirm
                  title="Bạn có chắc muốn đóng cuộc hội thoại này?"
                  onConfirm={disconnect}
                  onCancel={() => { }}
                  okText="Đóng"
                  cancelText="Hủy"
                >
                  <Tooltip title='Ẩn chatbox'>
                    <LineOutlined style={{ cursor: "pointer", fontSize: 18, marginRight: 10 }} onClick={() => setVisible(false)} />
                  </Tooltip>
                  <CloseOutlined style={{ cursor: "pointer", fontSize: 18 }} />
                </Popconfirm>
              </div>

              {/* Chat Form (Before Starting Chat) */}
              {!chatStarted && (
                <Form style={{ display: chatStarted ? 'none' : 'block' }} form={form} labelWrap={14} layout="vertical" onFinish={startChat} name="form-livechat">
                  <Form.Item>
                    <Typography.Text>
                      Kính chào Quý khách! Mời Quý khách đặt câu hỏi để 247Express tiếp nhận và hỗ trợ.</Typography.Text>
                  </Form.Item>
                  <Form.Item
                    label="Tên khách hàng"
                    name="name"
                    rules={[{ required: true, message: "Vui lòng nhập tên" }]}
                  >
                    <Input size="small" placeholder="Nhập tên của bạn" />
                  </Form.Item>

                  <Form.Item label="Số điện thoại" name="phone"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập số điện thoại',
                      },
                      {
                        pattern:
                          /^(((\+?84|0)?(86|54|96|77|87|55|89|52|97|98|87|32|33|34|35|36|37|38|39|89|90|93|70|79|77|76|78|88|91|94|83|84|85|81|82|92|56|58|99|59)))[0-9]{7}$|^(0((((28|24|77)[0-9])|(290|291|299|293|297|292|296|270|294|275|277|273|272|254|251|276|274|271|252|263|259|261|262|257|269|256|260|255|235|236|234|233|232|239|238|237|229|228|227|226|221|225|220|222|211|210|218|212|215|213|214|207|216|209|219|203|204|205|206))))[0-9]{7}$/,
                        message: 'Số điện thoại không hợp lệ',
                      },
                    ]}
                  >
                    <Input size="small" placeholder="Nhập số điện thoại" />
                  </Form.Item>
                  <Form.Item label="Tin nhắn" name="message" rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập tin nhắn',
                      whitespace: true
                    },
                    {
                      max: 255,
                      message: 'Tin nhắn không được quá 255 ký tự'
                    }
                  ]}>
                    <Input.TextArea placeholder="Tin nhắn" rows={3} />
                  </Form.Item>
                  {/* Start Chat Button */}
                  <Button type="primary" size="large" htmlType="submit" icon={<SendOutlined />} block className="btn-start-chatting">
                    Bắt đầu trò chuyện
                  </Button>
                </Form>

              )}
              {chatStarted && (
                <>
                  {/* Chat Messages */}
                  <div
                    style={{
                      maxHeight: "400px",
                      minHeight: "400px",
                      overflowY: "auto",
                      padding: "15px",
                      backgroundColor: "#f3f3f3",
                      borderRadius: 10,
                      //marginBottom: 10,
                    }}
                    ref={divChatContainerRef}
                    onScroll={handleScrollToTop}
                  >
                    {messageList}
                    <div ref={divChatRef}></div>
                  </div>

                  {/* Message Input */}
                  <div style={{ display: "flex", gap: 8, padding: 8 }}>
                    <Form form={form} style={{ width: "100%" }}>
                      <Row>
                        <Col span={3}>
                          <Form.Item>
                            <Upload {...props}>
                              <Button type="primary" shape="circle" icon={<PaperClipOutlined />} />
                            </Upload>
                          </Form.Item>
                        </Col>
                        <Col span={21}>
                          <Form.Item name={"content"}>
                            <Input
                              placeholder="Nhập tin nhắn..."
                              size="small"
                              style={{ height: 34, padding: "0px 16px", width: "100%" }}
                              //value={inputMessage}
                              //onChange={(e) => setInputMessage(e.target.value)}
                              onPressEnter={sendMessage}
                              suffix={
                                <Tooltip title="Gửi tin nhắn">
                                  <SendOutlined style={{ color: 'rgba(0,0,0,.45)' }} onClick={sendMessage} />
                                </Tooltip>
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>

  );
});
LiveChat.displayName = 'LiveChat';

export default LiveChat;
